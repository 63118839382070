<template>
  <v-container>
    <Consulta
        ref="filtroConsulta"
        titulo="Consulta de Instituição de Ensino Superior"
        class="justify-center"
        :parametros="paramsFiltro"
        :colunas="headers"
        :ordenacao-colunas="sortBy"
        :consulta-callback="consulta"
    >
      <template v-slot:areaParametros>
        <v-row dense>
          <v-col cols="6">
            <v-text-field
                outlined
                dense
                label="Nome da Instituição"
                v-model="paramsFiltro.nome"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
                outlined
                dense
                label="Sigla"
                v-model="paramsFiltro.sigla"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </template>
      <template v-slot:areaParametrosBotoesExtra>
        <v-btn color="primary" @click="abrirDialog">
          <v-icon left>mdi-plus-thick</v-icon>
          Adicionar
        </v-btn>
      </template>
      <template v-slot:acoesMenu="{ item }">
        <v-btn block text @click="editarIES(item)">
          Editar
        </v-btn>
      </template>
    </Consulta>
    <v-dialog v-if="dialog" v-model="dialog" width="400">
      <v-card>
        <v-card-title class="white--text primary">
          {{ isCriandoIES ? "Nova Instituição de Ensino" : "Atualizar Instituição de Ensino" }}
          <v-spacer/>
          <v-btn icon color="white" @click="cancelarEdicaoIES">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-form ref="edicaoIESForm"
                @submit.prevent="salvarEdicaoIES">
          <v-card-text class="mt-5">
              <v-text-field
                  v-model="instituicaoEnsinoEditada.nome"
                  :rules="[campoObrigatorioRule]"
                  dense
                  label="Nome da Instituição de Ensino *"
                  outlined
                  validate-on-blur
              ></v-text-field>
              <v-text-field
                  v-model="instituicaoEnsinoEditada.sigla"
                  :rules="[campoObrigatorioRule]"
                  dense
                  label="Sigla da Instituição de Ensino *"
                  outlined
                  validate-on-blur
              ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" @click.prevent="cancelarEdicaoIES">
              Cancelar
            </v-btn>
            <v-btn color="primary" type="submit">
              Salvar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Consulta from "@/components/pages/admin/consultas/Consulta";
import instituicaoEnsinoService from "@/services/instituicaoEnsino.service";
import rules from "@/commons/rules";
import {mapActions} from "vuex";

export default {
  name: "InstituicaoEnsinoSuperior",
  components: {Consulta},

  data() {
    return {
      headers: [
        {text: "Código", value: "codigo", mustSort: true},
        {text: "Nome", value: "nome"},
        {text: "Sigla", value: "sigla"},
      ],
      paramsFiltro: {},
      instituicaoEnsinoEditada: {},
      sortBy: [],
      consulta: instituicaoEnsinoService.recuperarFiltro,
      instituicoesEnsino: [],
      dialog: false,
    }
  },

  computed: {
    isCriandoIES() {
      return !this.instituicaoEnsinoEditada.oid;
    },
  },

  methods: {
    ...rules,
    ...mapActions("avisos", ["exibirAviso"]),

    async editarIES(instituicaoEnsino) {
      await instituicaoEnsinoService
          .recuperarInstituicaoEnsinoPorOid(instituicaoEnsino.oid)
          .then(response => {
            this.instituicaoEnsinoEditada = response.data;
          }).catch(() => {
          });

      this.dialog = true;
    },

    async salvarEdicaoIES()
    {
      if(this.$refs.edicaoIESForm.validate()) {
        let loader = this.$loading.show();

        if(this.isCriandoIES) {
          await instituicaoEnsinoService
              .criarIES(this.instituicaoEnsinoEditada)
              .then(() => {
                this.$refs.filtroConsulta.getDataFromApi();

                this.exibirAviso({
                  mensagem: "Instituição de Ensino criada com sucesso!",
                  tipo: "success"
                });
              })
              .catch(error => {
                this.exibirAviso({
                  mensagem: error.response.data.msg,
                  tipo: "error"
                });
              });
        } else {
          await instituicaoEnsinoService
              .atualizarIES(this.instituicaoEnsinoEditada)
              .then(() => {
                this.$refs.filtroConsulta.getDataFromApi();

                this.exibirAviso({
                  mensagem: "Instituição de Ensino atualizada com sucesso",
                  tipo: "success"
                });
              })
              .catch(error => {
                this.exibirAviso({
                  mensagem: error.response.data.msg,
                  tipo: "error"
                });
              });
        }
        this.cancelarEdicaoIES();

        loader.hide();
      }
    },

    cancelarEdicaoIES() {
      this.dialog = false;
      this.instituicaoEnsinoEditada = {};
      setTimeout(() => {
        this.$refs.edicaoIESForm.reset();
        this.cepEditado = {};
      }, 500);
    },
    abrirDialog() {
      this.dialog = true
    }
  }
}


</script>

<style scoped>

</style>